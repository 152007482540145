<template>
  <div>
    <jor_18bar />

    <v-container fluid>
      <base-material-card
        icon="mdi-clipboard-text"
        title="ข้อมูลสถานศึกษารายงาน สาขาวิชาสำหรับตำแหน่งว่าง"
        class="px-5 py-3"
      >
        <v-row no-gutters>
          <v-col cols="12" md="6">
            <div class="text-center font-weight-bold">
              <v-chip color="success" class="mr-1">
                <h3>
                  <v-icon>mdi-check-circle</v-icon> {{ collegeReport.countcf }}
                </h3>
              </v-chip>
              <v-chip color="error" class="font-weight-bold mr-1">
                <h3><v-icon>mdi-alert</v-icon> {{ collegeReport.countcc }}</h3>
              </v-chip>

              <v-chip color="warning" class="font-weight-bold mr-2">
                <h3>
                  <v-icon>mdi-progress-tag</v-icon> {{ collegeReport.countpc }}
                </h3>
              </v-chip>
              <v-btn color="warning" rounded outlined small @click="clearCc()"> <v-icon>mdi-alert</v-icon> Clear </v-btn>
            </div>
          </v-col>
          <v-col>
            <div class="text-center mb-1">
              <v-btn  href="#/admin/print_jor18_teacherReport/"
              target="_blank" outlined rounded color="info" class="mr-2"
                ><v-icon>mdi-printer</v-icon> พิมพ์รายงาน แบบที่ 1 
              </v-btn> 

              <v-btn  href="#/admin/print_jor18_teacherReport_2/"
              target="_blank" outlined rounded color="info" class="mr-2"
                ><v-icon>mdi-printer</v-icon> พิมพ์รายงาน แบบที่ 2
              </v-btn>

              <v-btn  href="#/admin/print_jor18_teacherReport_3/"
              target="_blank" outlined rounded color="info"
                ><v-icon>mdi-printer</v-icon> พิมพ์รายงาน แบบที่ 3
              </v-btn>
            </div>
          </v-col>
        </v-row>
        <v-data-table
          color="success"
          :headers="showHeaders"
          :items="college_manpowers"
          :search="search"
          class="mt-1"
        >
          <template v-slot:top>
            <div>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="ค้นหา ระบุคำ หรือ ส่วนข้อความเกี่ยวข้อง"
                single-line
                hide-details
                dense
                filled
                class="mb-2"
              />
            </div>
            <div>
              <v-select
                v-model="selectedHeaders"
                :items="headers"
                label="เลือกคอลัมน์ที่ต้องการแสดง"
                multiple
                outlined
                return-object
              >
                <template v-slot:selection="{ item, index }">
                  <v-chip v-if="index < 6">
                    <span>{{ item.text }}</span>
                  </v-chip>
                  <span v-if="index === 6" class="grey--text caption"
                    >(+{{ selectedHeaders.length - 6 }} คอลัมน์)</span
                  >
                </template>
              </v-select>
            </div>
          </template>

          <template v-slot:item.college_manpowerfile="{ item }">
            <div>
              <v-icon
                v-if="item.college_manpowerfile != '-'"
                size="30"
                color="info"
                >mdi-file-pdf-box</v-icon
              >
            </div>
          </template>

          <template v-slot:item.college_manpower_status="{ item }">
            <div>
              <v-btn
                v-if="item.college_manpower_status === 'cc'"
                outlined
                rounded
                color="red"
                @click="cancelAlert(item.college_manpower_ref)"
              >
                <v-icon size="30" color="red">mdi-alert</v-icon>
                <v-icon size="30" color="red">mdi-closed-caption</v-icon>
              </v-btn>

              <v-icon
                v-else-if="item.college_manpower_status === 'cf'"
                color="success"
                >mdi-check-circle</v-icon
              >

              <v-icon size="30" v-else color="warning">mdi-progress-tag</v-icon>
            </div>
          </template>

          <template v-slot:item.college_manpower_timestamp="{ item }">
            <div>
              {{
                item.college_manpower_timestamp
                  | moment("add", "543 year")
                  | moment("D MMMM YYYY")
              }}
            </div>
          </template>

          <template v-slot:item.del_action="{ item }">
            <div>
              <v-btn
                @click="delReport(item.college_manpower_ref)"
                color="error"
                fab
                small
                dark
                ><v-icon size="30">mdi-delete-circle</v-icon></v-btn
              >
            </div>
          </template>
          <v-alert
            slot="no-results"
            :value="true"
            color="error"
            icon="mdi-alert"
            >ไม่พบผลลัพธ์ "{{ search }}" ที่คุณกำลังค้นหา.</v-alert
          >
        </v-data-table>
      </base-material-card>
    </v-container>
  </div>
</template>
<script>
import jor_18bar from "../../components/admin/jor_18bar";

export default {
  components: { jor_18bar },
  data() {
    return {
      loading: true,
      ApiKey: "HRvec2021",
      search: "",
      headers: [],
      selectedHeaders: [],
      addcollege_manpowerdialog: false,
      headersMap: [
        { text: "ID_Ref", align: "left", value: "college_manpower_ref" },
        {
          text: "สถานศึกษา",
          align: "left",
          value: "college_manpower_idcollege"
        },
        { text: "สถานศึกษา", align: "left", value: "user_firstname" },
        { text: "เลขที่ตำแหน่ง", align: "center", value: "college_manpower_idposition" },
        { text: "สาขาวิชา", align: "left", value: "name_branch" },
        { text: "เหตุผล", align: "left", value: "college_manpower_comment" },

        {
          text: "สถานะ",
          align: "center",
          value: "college_manpower_status"
        }
      ],

      editedIndex: -1,
      editedItem: {
        id_no: 0,
        j18_reason: ""
      },
      editedItemUpdate: {},
      defaultItem: {
        id_no: 0,
        j18_reason: ""
      },
      deletedItemmat: {},
      deletedItemmcm: {},
      college_manpowers: [],
      collegeReport: [],
      college_manpowerCC: [],
      college_manpower_CC: {}
    };
  },
  async created() {
    this.headers = Object.values(this.headersMap);
    this.selectedHeaders = this.headers;
  },

  async mounted() {
    await this.periodQuery();
    await this.college_manpowerQueryAll();
  },

  methods: {
    async clearCc(){
      let result = await this.$http.post("college_manpower.updateclearcc.php",{
        ApiKey:this.ApiKey
      })
      if (result.data.status == true) {
            Swal.fire({
              title: "ดำเนินการข้อมูลเรียบร้อย",
              icon: "success",
              showConfirmButton: false,
              timer: 1500
            });
            await this.college_manpowerQueryAll();
          } else {
            Swal.fire({
              icon: "warning",
              title: "ไม่พบรายการที่ต้องปรับปรุง",
              showConfirmButton: false,
              timer: 1500
            });
          }
    },
    async delReport(college_manpower_ref) {
      let result = await this.$http.post("college_manpower.php", {
        ApiKey: this.ApiKey,
        college_manpower_ref: college_manpower_ref
      });
      this.college_manpowerCC = result.data;

      Swal.fire({
        title: "ลบรายการ",
        text: "ลบเพื่อดำเนินการสร้างใหม่",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก"
      }).then(async result => {
        if (result.isConfirmed) {
          this.college_manpowerCC.ApiKey = this.ApiKey;

          let result_delete = await this.$http.post("deletefile.php", {
            ApiKey: this.ApiKey,
            filename:
              "../HRvecfiles/" + this.college_manpowerCC.college_manpowerfile
          });

          let resultup = await this.$http.post(
            "college_manpower.delete.php",
            this.college_manpowerCC
          );

          this.college_manpower_CC.ApiKey = this.ApiKey;
          this.college_manpower_CC.college_manpower_ref = college_manpower_ref;

          let resultuprp = await this.$http.post(
            "college_manpower.deleteidref.php",
            this.college_manpower_CC
          );

          if (resultup.data.status == true) {
            Swal.fire({
              title: "ดำเนินการข้อมูลเรียบร้อย",
              icon: "success",
              showConfirmButton: false,
              timer: 1500
            });
            await this.college_manpowerQueryAll();
          } else {
            Swal.fire({
              icon: "warning",
              title: "ผิดพลาด",
              showConfirmButton: false,
              timer: 1500
            });
          }
        }
      });
    },
    async cancelAlert(college_manpower_ref) {
      let result = await this.$http.post("college_manpower.php", {
        ApiKey: this.ApiKey,
        college_manpower_ref: college_manpower_ref
      });
      this.college_manpowerCC = result.data;

      Swal.fire({
        title: "ขอยกเลิกการแนบไฟล์",
        text: "เนื่องจากแนบไฟล์ผิดพลาด",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก"
      }).then(async result => {
        if (result.isConfirmed) {
          this.college_manpowerCC.ApiKey = this.ApiKey;

          let result_delete = await this.$http.post("deletefile.php", {
            ApiKey: this.ApiKey,
            filename:
              "../HRvecfiles/" + this.college_manpowerCC.college_manpowerfile
          });

          this.college_manpowerCC.college_manpowerfile = "-";

          this.college_manpowerCC.college_manpower_status = "-";

          let resultup = await this.$http.post(
            "college_manpower.update.php",
            this.college_manpowerCC
          );

          if (resultup.data.status == true) {
            Swal.fire({
              title: "ดำเนินการข้อมูลเรียบร้อย",
              icon: "success",
              showConfirmButton: false,
              timer: 1500
            });
            await this.college_manpowerQueryAll();
          } else {
            Swal.fire({
              icon: "warning",
              title: "ผิดพลาด",
              showConfirmButton: false,
              timer: 1500
            });
          }
        }
      });
    },
    async periodQuery() {
      let result_period_college = await this.$http.post("period_college.php", {
        ApiKey: this.ApiKey,
        period_college_id: "701"
      });
      this.period_colleges = result_period_college.data;
    },

    close() {
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },

    async editItem(item) {
      this.editedIndex = this.college_manpowers.indexOf(item);
      this.editedItem = Object.assign({}, item);
    },

    async save() {
      this.editedItem.ApiKey = this.ApiKey;
      Object.assign(this.college_manpowers[this.editedIndex], this.editedItem);
      let resultmat = await this.$http.post(
        "college_manpower.updateIdno.php",
        this.editedItem
      );
      if (resultmat.data.status == true) {
        Swal.fire({
          icon: "success",
          title: "บันทึกข้อมูลเรียบร้อย",
          showConfirmButton: false,
          timer: 1500
        });
      } else {
        Swal.fire({
          icon: "warning",
          title: "ทำรายการซ้ำ",
          showConfirmButton: false,
          timer: 1500
        });
      }
      this.close();
    },

    async college_manpowerQueryAll() {
      this.loading = true;
      let result = await this.$http
        .post("college_manpower.php", {
          ApiKey: this.ApiKey,
          college_manpower_time: this.period_colleges.period_college_times,
          college_manpower_year: this.period_colleges.period_college_year
        })
        .finally(() => (this.loading = false));
      this.college_manpowers = result.data;
      let data = result.data;
      let countcc = 0;
      let countcf = 0;
      let countpc = 0;
      data.forEach(value => {
        if (value.college_manpower_status == "cc") {
          countcc += 1;
        } else if (value.college_manpower_status == "cf") {
          countcf += 1;
        } else {
          countpc += 1;
        }
      });
      this.collegeReport.countcc = countcc;
      this.collegeReport.countcf = countcf;
      this.collegeReport.countpc = countpc;
    },
    async college_manpowerAdd() {
      this.addcollege_manpower = {};
      this.addcollege_manpowerdialog = true;
    },

    async sweetAlertLoading() {
      Swal.fire({
        title: "Please Wait !",
        timerProgressBar: true,
        didOpen: () => {
          Swal.showLoading();
        },
        onBeforeOpen: () => {
          Swal.showLoading();
        },
        onAfterClose: () => {
          Swal.hideLoading();
        },
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false
      });
    }
  },
  computed: {
    showHeaders() {
      return this.headers.filter(s => this.selectedHeaders.includes(s));
    },
    pages() {
      if (
        this.pagination.rowsPerPage == null ||
        this.pagination.totalItems == null
      )
        return 0;

      return Math.ceil(
        this.pagination.totalItems / this.pagination.rowsPerPage
      );
    },
    date_today_log() {
      let today = new Date();
      let dd = String(today.getDate()).padStart(2, "0");
      let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      let yyyy = today.getFullYear() + 543;
      let time =
        today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
      today = dd + "/" + mm + "/" + yyyy + "/" + time;
      return today;
    }
  }
};
</script>
